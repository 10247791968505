import 'core-js/stable';
import 'regenerator-runtime/runtime';

import initDriverAutocomplete from '../../packs/order/index';

import * as ActionCable from 'actioncable';

function playSound() {
  let audio = new Audio();
  audio.src = '/audio/sms-alert-3-daniel_simon.mp3';
  audio.play();
}

let App = {};

App.channelParams = { channel: 'OrderScheduledChannel' };
App.channelParams.store_name = gon.store_name;
App.cable = ActionCable.createConsumer();

App.orderScheduled = App.cable.subscriptions.create(
  { ...App.channelParams },
  {
    connected(e) {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },
    received(data) {
      const orderElement = $('#' + data['id']);
      const rowIsFound = orderElement.length;
      let orderIsNew = false;
      if (['Delivered'].includes(data['status']) || ['OnDelivery'].includes(data['status'])) {
        orderIsNew = true;
      }
      if ((data['scheduled'] && rowIsFound) || data['status'] == 'Canceled') orderElement.remove();
      else if (rowIsFound) orderElement.replaceWith(data['order']);
      else if (!orderIsNew && !data['scheduled'] && data['status'] != 'Canceled') $('#scheduled_orders_table_body').prepend(data['order']);

      if (['Open'].includes(data['status']) || (['Confirmed'].includes(data['status']) && data['is_mobile'])) playSound();

      setTimeout(() => {
        initDriverAutocomplete(data.id);
      });
    },

    new_order() {
      perform('new_order');
    },
  }
);
